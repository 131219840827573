
    var riot = require('riot')
    
riot.tag2('roombookings', '<div show="{!bookings}"> <h4>Loading Bookings</h4> </div> <div show="{bookings.length == 0}"> <h4>You have no room bookings at this time</h4> </div> <div show="{bookings.length &gt; 0}" class="table-responsive"> <table class="table table-striped"> <thead> <tr> <th>Ref</th> <th>Facility</th> <th>Start</th> <th>End</th> <th>Invoice</th> </tr> </thead> <tbody> <tr each="{bookings}"> <td> {(⁗0000000000⁗+id).substr(-4)} </td> <td> <a href="{path}">{name}</a></td> <td> <dateformat date="{bookings.start}" format="dd MMM yyyy - h:mm a"></dateformat> </td> <td> <dateformat date="{bookings.start}" format="dd MMM yyyy - h:mm a"></dateformat> </td> <td> <button class="btn btn-sm btn-default">View Invoice </button> </td> </tr> </tbody> </table> </div>', '', '', function(opts) {
var xhr;

xhr = require("../libs/xhr.coffee");

this.on("mount", function() {
  if (!window.location.search.match(/editmode=/)) {
    return this.getBookings((function(_this) {
      return function(bookings) {
        _this.bookings = bookings;
        return _this.update();
      };
    })(this));
  }
});

this.getBookings = (function(_this) {
  return function(cback) {
    return xhr("GET", "/duocms/api/bookings/my", null, function(res) {
      if (cback) {
        return cback(res.body.data);
      }
    });
  };
})(this);
});
    
  