# import {component} from "duo-kit"
# isEditMode = window.location.search.match(/editmode=true/)

window.riot = riot = require 'riot'
require 'riot-kit/lib/slideshow'
require "riot-kit/lib/img_loader"
require 'riot-kit/lib/form'
require 'riot-kit/lib/twitter'
require 'riot-kit/lib/modal'
require 'riot-kit/lib/form'
require 'riot-kit/lib/ecommerce'
require 'riot-kit/lib/pagination'
require 'riot-kit/lib/dateformat'
require 'riot-kit/lib/dropdown'
require 'riot-kit/lib/textresize'
require 'riot-kit/lib/toaster'
# require 'riot-kit/lib/gmap'
require 'riot-kit/lib/formdate'
require 'riot-kit/lib/tabs'

require('riot-kit/lib/auth/forgot-password')

require './tags/loginbar.tag'
require './tags/ecommercebar.tag'
require './tags/navigation.tag'
require './tags/mydetails.tag'
require './tags/mypassword.tag'
require './tags/signup.tag'
require './tags/tags-input.tag'
require './tags/comments_input.tag'
require './tags/comments_output.tag'
require './tags/recaptcha.tag'
require './tags/pricetag.tag'
require './tags/interpreter.tag'
require './libs/slideshow.coffee'
require './tags/uploadimage.tag'
require './tags/interpreter-booking.tag'
require './tags/formdatetime.tag'

require './tags/my-bookings.tag'
require './tags/roombookings.tag'
require './tags/appointments.tag'
require './tags/interpreter-appointments.tag'
require './tags/uploadbutton.tag'
require './tags/booking-calendar.tag'
require './tags/gmap.tag'

$ = jQuery = window.jQuery = window.$ = require "jquery"
require 'owl.carousel'

# import Consultation from "./tags/mdc-consultation.svelte"
# component({component:Consultation,tagname:"mdc-consultation",href:"/resources/css/site.css",shadow:isEditMode,attributes:["id"]})

# csrf fix vvvvv
updateCSRF = =>
  req = new XMLHttpRequest()
  req.open("get","/", true) # get home page to refresh cookie
  req.onreadystatechange = () =>
    if req.readyState == 4
      csrfcookie = document.cookie.match(/_csrf=([^ ;]+)/)
      console.log "csrf 1",csrfcookie
      if csrfcookie && csrfcookie.length > 0
        console.log csrfcookie
        csrf = csrfcookie[1]  
        console.log csrf
        fields = document.querySelectorAll('input[name="_csrf"]')
        if fields && fields.length > 0
          console.log fields
          fields.forEach (field)=>
            console.log("updated cookie",field)
            field.setAttribute("value",csrf)
  req.send()
  setTimeout (=> updateCSRF(fields)),5*30*1000 # call again in 30 mins

fields = document.querySelectorAll('input[name="_csrf"]')
if fields && fields.length
  setTimeout (=> updateCSRF(fields)),5*30*1000
# csrf fix ^^^^^

setTimeout(=>
  $(document).ready ()=>
    $('body:not(.editmode) .dl_partners.owl-carousel').owlCarousel(
      items:8
      pagination:false
      nav:true
      margin: 0
      autoplay:true
      autoplayHoverPause:true
      loop:true
      lazyLoad:true
      responsive:{
        0 : {items:1}
        480:{items:2}
        768:{items:4}
        992 : {items:8}
      }
    ).addClass('show')
,500)

riot.mount('*')
document.addEventListener 'duocms:updated', -> riot.mount('slideshow,gmap')