
    var riot = require('riot')
    
riot.tag2('appointments', '<div show="{!appointments}"> <h4>Loading Interpreter Bookings</h4> </div> <div show="{appointments.length == 0}"> <h4>You have no interpreter bookings at this time</h4> </div> <div show="{appointments.length &gt; 0}" class="table-responsive"> <table class="table table-striped"> <thead> <tr> <th>Ref</th> <th>PO No.</th> <th>Cost Centre</th> <th>Location</th> <th>When</th> <th>Service</th> <th>Status</th> <th>Actions</th> </tr> </thead> <tbody> <tr each="{appointments}"> <td>{(⁗0000000000⁗+id).substr(-4)}</td> <td>{meta.po_number}</td> <td>{meta.cost_center_code}</td> <td>{venue}</td> <td> <dateformat date="{new Date(when)}" format="dd MMM yyyy - h:mm a"></dateformat> </td> <td>{parent.service(service)}</td> <td>{status || ⁗Awaiting Approval⁗}</td> <td> <button __disabled="{new Date(when) &lt; new Date() || status==\'Booking Cancelled\'}" onclick="{showNotice}" class="btn btn-sm btn-default">Cancel Booking</button> </td> </tr> </tbody> </table> </div> <modal name="cancelconfirm" title="Please Confirm" onclose="{true}" onsave="{cancelBooking}" savetext="Confirm Cancelation"> <div show="{parent.willpay}" class="alert alert-danger"> <p>Please note: you will still need to pay for this appointment as you have not given more than 7 days notice.</p> </div> <p>Clicking cancel will email MDC and your assigned interpreter to let them know the appointment is no longer going ahead.</p> </modal><a href="/services/booking-an-interpreter/booking-form/" class="btn btn-primary">Create A New Booking</a>', '', '', function(opts) {
var xhr;

xhr = require("../libs/xhr.coffee");

this.services = require("../libs/service-list.coffee");

this.on("mount", function() {
  if (!window.location.search.match(/editmode=/)) {
    return this.getAppointments((function(_this) {
      return function(appointments) {
        _this.appointments = appointments;
        return _this.update();
      };
    })(this));
  }
});

this.getAppointments = (function(_this) {
  return function(cback) {
    return xhr("GET", "/duocms/api/appointments/user", null, function(res) {
      var data;
      data = res.body.data.map(function(row) {
        row.meta = JSON.parse(row.meta);
        return row;
      });
      if (cback) {
        return cback(res.body.data);
      }
    });
  };
})(this);

this.service = (function(_this) {
  return function(val) {
    var found, ref;
    found = _this.services.filter(function(s) {
      return s.value === val;
    });
    return (found != null ? (ref = found[0]) != null ? ref.label : void 0 : void 0) || "";
  };
})(this);

this.showNotice = (function(_this) {
  return function(e) {
    _this.selected = e.item;
    console.log(Math.abs(new Date() - new Date(_this.selected.when)));
    _this.willpay = Math.abs(new Date() - new Date(_this.selected.when)) < 7 * 24 * 60 * 60 * 1000;
    return _this.tags.cancelconfirm.show();
  };
})(this);

this.cancelBooking = (function(_this) {
  return function(e) {
    return xhr("POST", "/duocms/api/appointments/" + _this.selected.id + "/cancel", null, function(res) {
      _this.tags.cancelconfirm.hide();
      return _this.getAppointments(function(appointments) {
        _this.appointments = appointments;
        return _this.update();
      });
    });
  };
})(this);
});
    
  