
    var riot = require('riot')
    
riot.tag2('uploadbutton', '<yield></yield> <input type="file" onchange="{changed}">', 'uploadbutton{ position:relative; overflow:hidden; } uploadbutton input{ position:absolute; top:0px; left:0px; right:0px; bottom:0px; opacity:0.01; }', '', function(opts) {
this.changed = (function(_this) {
  return function(e) {
    var files;
    files = e.dataTransfer ? e.dataTransfer.files : e.target ? e.target.files : [];
    if (opts.onClick) {
      opts.onClick(files);
    }
    _this.root.querySelector('input').value = "";
    return false;
  };
})(this);
});
    
  