
    var riot = require('riot')
    
riot.tag2('uploadimage', '<yield></yield> <input type="file" onchange="{changed}" accept="image/*">', 'uploadimage { position: relative; overflow: hidden; display: block; margin-bottom: 15px; } uploadimage input { position: absolute; top: 0px; left: 0px; right: 0px; bottom: 0px; opacity: 0.01; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=1)"; filter: alpha(opacity=1); }', '', function(opts) {
this.changed = (function(_this) {
  return function(e) {
    var files;
    files = e.dataTransfer ? e.dataTransfer.files : e.target ? e.target.files : [];
    if (opts.onClick && (files != null ? files[0] : void 0)) {
      opts.onClick(files[0]);
    }
    _this.root.querySelector('input').value = "";
    return false;
  };
})(this);

this.getBase64 = (function(_this) {
  return function(file, cback) {
    var reader;
    reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      return cback(reader.result);
    };
    return reader.onerror = function(error) {
      return console.log('uploadimage tag error: ', error);
    };
  };
})(this);
});
    
  