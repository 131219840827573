
    var riot = require('riot')
    
riot.tag2('my-bookings', '<tabs> <tab heading="Interpreter Bookings" active="true"> <br> <appointments></appointments> </tab> <tab heading="Room Bookings"><br> <roombookings> </roombookings> </tab> </tabs>', '', '', function(opts) {
this.on("mount", function() {
  if (window.location.search.match(/editmode=/)) {
    return this.root.innerHTML = "<div style='border:2px dashed #a00'>My Bookings Component</div>";
  }
});
});
    
  